$sidebar-bg-color: #9a2a3f;

@import "hydeout";
@import "hydeout/syntax";

.home #sidebar {
  .site-title {
    font-size: 2rem;
  }
}
@media (min-width: $large-breakpoint) {
  #sidebar {
    .site-title {
      font-size: 2rem;
    }
  }
}

#sidebar nav > .icon {
  margin: 0 0.2em;
}

.container > * {
  max-width: 46rem;
}

figure {
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}


.article_image img {
  max-width: 23rem;
}
